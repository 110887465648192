<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let useModalForLogin: boolean = false;
	export let registeringFunnelPath: string = '';

	export let newAccountCTA: string = 'Create my uncensored AI companion';
	export let shortCTA: string = newAccountCTA;

	const dispatch = createEventDispatcher();

	const open = (type: 'login' | 'register') => {
		dispatch('open', { type: type });
	};
</script>

<div
	class="header--bg sticky inset-0 z-10 flex min-h-[63px] w-full flex-col border-b border-white/20 pb-[6px] pt-[10px] sm:h-[64px] sm:pt-3"
>
	<div
		class="mx-auto flex h-full w-full max-w-7xl flex-row flex-wrap items-start justify-between gap-x-1 gap-y-2 px-2 sm:px-4"
	>
		<a href="/" class="my-auto sm:my-0" title="Go back to home page">
			<img class="max-h-[30px] sm:max-h-[40px]" src="/logo.png" alt="logo nastia" />
		</a>

		<div class="ml-auto flex flex-row gap-2">
			<a
				href="/auth/login"
				on:click={(event) => {
					if (useModalForLogin) {
						event.preventDefault();
						event.stopPropagation();

						open('login');
					}
				}}
				class="flex items-center justify-center rounded-xl border border-white px-2 font-gigasans text-sm hover:bg-white/20 sm:flex md:px-4 md:text-base"
			>
				Sign in
			</a>
			<a
				href={registeringFunnelPath || '/n/onboarding/create-ai'}
				on:click={(event) => {
					if (useModalForLogin && !registeringFunnelPath) {
						event.preventDefault();
						event.stopPropagation();

						open('register');
					}
				}}
				class="v2-btn primary active flex items-center justify-between px-2 font-gigasans text-sm font-medium md:px-4 md:text-base"
			>
				<enhanced:img
					src="/static/images/web/loginregister-modal.webp"
					alt=""
					class="size-6 rounded-full"
				/>
				<span class="hidden sm:inline">{@html newAccountCTA}</span>
				<span class="sm:hidden">{@html shortCTA}</span>
			</a>
		</div>
	</div>
</div>

<style>
	.header--bg {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 33.21%, rgba(0, 0, 0, 0) 94.72%);
		backdrop-filter: blur(5px);
	}

	.sticky {
		position: -webkit-sticky;
		position: sticky;
	}
</style>
